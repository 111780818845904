import {Link} from 'gatsby';
import React from 'react';
import styled from 'styled-components';

import {breakpointsMediaQueries, COLORS} from '../style/my-theme';

const StyledMainButton = styled.button.attrs({
  className: 'main-button'
})`
  background: ${COLORS.yellow};
  color: ${COLORS.primary};
  border: none;
  box-shadow: 0px 5px 0px ${COLORS.primary};
  border-radius: 0.5rem;
  margin: 2rem 0;
  padding: 0 28px;
  height: 44px;
  line-height: 44px;
  font-size: 1.2rem;
  font-weight: 600;
  outline: none;

  &:hover {
    background: ${COLORS.yellowLight};
    cursor: pointer;
  }
  ${breakpointsMediaQueries.md} {
    margin: 2rem 0;
    width: auto;
    height: 3rem;
  }
`;

const StyledCenterLinkWrapper = styled.div.attrs({
  className: 'button-link-wrapper'
})`
  display: flex;
  justify-content: center;
`;

export const ExternalLinkButton = ({label, url}: {label: string; url: string}) => {
  return (
    <StyledCenterLinkWrapper>
      <a href={url} target="_blank">
        <StyledMainButton>{label}</StyledMainButton>
      </a>
    </StyledCenterLinkWrapper>
  );
};

export const FientaLinkButton = ({label, url}: {label: string; url: string}) => {
  return (
    <StyledCenterLinkWrapper>
      <a href={url}>
        <StyledMainButton>{label}</StyledMainButton>
      </a>
    </StyledCenterLinkWrapper>
  );
};

export const InternalLinkButton = ({label, url}: {label: string; url: string}) => {
  return (
    <StyledCenterLinkWrapper>
      <Link to={url}>
        <StyledMainButton>{label}</StyledMainButton>
      </Link>
    </StyledCenterLinkWrapper>
  );
};

export const ContainerLg = styled.div.attrs({
  className: 'container-lg'
})`
  max-width: ${props => props.theme.containerMaxWidth}px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
  overflow: hidden;

  ${breakpointsMediaQueries.xl} {
    margin: 0px 40px;

    &.no-margin {
      margin: 0 0;
    }
  }
  ${breakpointsMediaQueries.sm} {
    margin: 0px 32px;

    &.no-margin {
      margin: 0 0;
    }
  }
`;
